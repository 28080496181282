import type { NodeItem } from 'o365-nodedata';
import type DataColumn from './DataGrid.DataColumn.ts';
import type { DataItemModel } from 'o365-dataobject/types.ts';

import { DataGridControl } from './DataGridControl.ts';
import { watch, nextTick } from 'vue';
import { logger } from 'o365-utils';
import { addEventListener } from 'o365-vue-utils';
// import logger from 'o365.modules.Logger.ts';

declare module './DataGridControl.ts' {
    interface DataGridControl {
        navigation: DataGridNavigation;
        hasNavigation: boolean;
    }
}

Object.defineProperties(DataGridControl.prototype, {
    'navigation': {
        get() {
            if (this._navigation == null) {
                this._navigation = new DataGridNavigation(this);
            }
            return this._navigation;
        }
    },
    'hasNavigation': {
        get() {
            return !!this._navigation;
        }
    }
});

/** DataGrid navigation extension */
class DataGridNavigation {
    private _dataGridControl: DataGridControl;
    private _initialized = false;

    private _cleanupTokens: (() => void)[] = [];
    private _currentIndexFocusDebounce: number | null = null;
    private _mouseUpOperation?: () => void;
    private _ctMouseMove?: () => void;
    private _isDragging = false;
    private _wasDragging = false;
    private _focusedThroughClick = false;
    private _mouseDownElement?: HTMLElement;
    private _editorActivated = false;

    private _activeCell: Cell | null = null;

    private get _activeSelection(): AreaSelection | null {
        return this._dataGridControl.selectionControl?.selection ?? null;
    }
    private set _activeSelection(pSelection: AreaSelection | null) {
        if (this._dataGridControl.selectionControl) {
            this._dataGridControl.selectionControl.selection = pSelection ?? undefined;
        }
    }

    private _selectionClassMap?: SelectionClassMap;
    private _editMode: boolean = false;
    /** When activating edit mode will set the selection on supported editors to this value */
    private _editSelectionRange?: [number, number];

    private _registeredShortcutUID = 0;
    private _registeredShortcutMap = new Map<string, number>();
    private _registeredShortcuts = new Map<number, KeyboardShortcut<[KeyboardShortcutUtils]>>();

    /**
     * Containers render order used when navigating from one container to another  
     * HEADER ROWS  H_0_0  
     * NEW RECORDS  N_0_0  
     * FILTER ROW   F_0_0  
     * MAIN GRID    G_0_0  
     * SUMMARY      S_0_0  
     */
    private get _containersRenderOrder() {
        const order: GridSelectionContainer[] = [];
        if (this._dataGridControl.isTable) {
            // DataTable container order
            if (!this._dataGridControl.props.noHeader) {
                if (!this._dataGridControl.props.noHeaderRow) {
                    order.push(GridSelectionContainer.Header);
                }
                if (!this._dataGridControl.props.disableFilterRow) {
                    order.push(GridSelectionContainer.Filter);
                }
            }
            order.push(GridSelectionContainer.Main);
            if (this._dataGridControl.props.dataObject?.allowInsert && this._dataGridControl.props.dynamicLoading) {
                order.push(GridSelectionContainer.NewRecords);
            }
        } else {
            // DataGrid container order
            const dataObject = this._dataGridControl.dataObject;
            const hasNewRecords = !this._dataGridControl.props.disableBatchRecords && !this._dataGridControl.props.hideNewRecords
                && dataObject && dataObject.batchDataEnabled && dataObject.batchData.data.length > 0;
            if (!this._dataGridControl.props.noHeader) {
                if (!this._dataGridControl.props.noHeaderRow) {
                    order.push(GridSelectionContainer.Header);
                }
                if (hasNewRecords && this._dataGridControl.newRecordsPosition == 'above-filters') {
                    order.push(GridSelectionContainer.NewRecords);
                }
                if (!this._dataGridControl.props.disableFilterRow) {
                    order.push(GridSelectionContainer.Filter);
                }
            }
            order.push(GridSelectionContainer.Main);

            if (hasNewRecords && this._dataGridControl.newRecordsPosition == 'bottom') {
                order.push(GridSelectionContainer.NewRecords);
            }
        }
        return order;
    }
    /** Active editor ref */
    cellEditorRef?: CellEditorRef | null;
    /** Context menu component ref */
    contextMenuRef?: {
        dropdown: {
            isOpen: boolean,
            close: () => Promise<void>,
            open: () => void,
        },
        initItemValues: (pOptions: {
            column: DataColumn,
            row: DataItemModel,
            rowIndex: number,
            event: MouseEvent
        }) => void;
        setLocation: (pX: number, pY: number) => void
    } | null;

    /** Focused cell */
    get activeCell() {
        return this._activeCell;
    }
    /** Focused cell string */
    get activeCellString() {
        return this._activeCell ? this.cellToString(this._activeCell) : null;
    }
    /** Area selection  */
    get activeSelection() {
        return this._activeSelection;
    }
    /** Active selection classes mapped to cell coordinates */
    get selectionClassMap() { return this._selectionClassMap; }

    get isSingleSelection() {
        return this.activeSelection && this._equalLocations(this.activeSelection.start, this.activeSelection.end);
    }

    /** Indicates if grid is in edit mode */
    get editMode() { return this._editMode; }
    /** Indicates that area selection is currently being dragged */
    get isDragging() { return this._isDragging; }
    /** Indicates that current focus occured through click and not programmatically */
    get focusedThroughClick() { return this._focusedThroughClick; }
    set focusedThroughClick(pValue) { this._focusedThroughClick = pValue; }

    /** Current mousedown element, is cleared on mouseup. Used in click events */
    get mouseDownElement() { return this._mouseDownElement; }
    /** Grid body container (without the sidepanel) */
    get container() { return this._dataGridControl.scopedContainer ?? this._dataGridControl.container; }
    /** Helper getter for container enum without having to import this file */
    get containerEnum() { return GridSelectionContainer; }
    /** Editor was succesfuly activated on last attempt */
    get editorActivated() { return this._editorActivated; }

    constructor(pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
    }

    /** Should be called only once. Initalizes required watchers and events */
    initialize() {
        if (this._initialized) { return; }
        (this._dataGridControl as any).gridSelectionInterface = new GridSelectionInterface_Compatibility(this);
        (this._dataGridControl as any).gridFocusControl = new GridFocusControl_Compatibility(this);
        (this._dataGridControl as any).gridNavigationControl = new NavigationControl_Compatibility(this);
        this._cleanupTokens.push(watch(() => this._dataGridControl.selectionControl?.areaselection, (pNewSelection: AreaSelection) => {
            this.renderSelection(pNewSelection);
        }));
        this._cleanupTokens.push(watch(() => this._editMode, (pInEditMode: boolean) => {
            if (pInEditMode) {
                this.activateEditor();
            }
        }));
        this._cleanupTokens.push(watch(() => this.activeCellString, async () => {
            if (this._currentIndexFocusDebounce) { window.clearTimeout(this._currentIndexFocusDebounce); }
            this.container?.querySelectorAll('.o365-focus-cell').forEach(element => element.classList.remove('o365-focus-cell'));
            this.container?.querySelectorAll('.current-column').forEach(element => element.classList.remove('current-column'));
            const cell = this.activeCell;
            if (cell == null) { return; }
            let cellNode = this._getCellElement(cell);
            let cellNodeInView = false;
            if (cellNode == null) {
                await this.scrollToCell(cell);
                cellNode = this._getCellElement(cell);
                cellNodeInView = true;
                if (cellNode == null) { return; }
            }
            cellNode.classList.add('o365-focus-cell');
            if (cell.container == GridSelectionContainer.NewRecords) {
                const headerCell = this._getCellElement({
                    x: cell.x,
                    y: 0,
                    container: GridSelectionContainer.Header
                });
                if (headerCell) {
                    headerCell.classList.add('current-column')
                }
            }
            if (!cellNodeInView) {
                await this.scrollToCell(cell);
            }
            cellNode.focus();
            if (cell.y < 0) {
                // Temp compatability for new records (should be removed when new records behave same as main list)
                const column = this._dataGridControl.dataColumns.columns[cell.x];
                const pinned = column.pinned ?? 'center';
                const rowRefSelector = `editor_row_${pinned}_${cell.y}`;
                const cellRefSelector = `editor_col_${cell.x}`;
                const rowRef = this._dataGridControl.newrecordsRef.$refs[rowRefSelector];
                let cellRef = rowRef.$refs[cellRefSelector];
                if (Array.isArray(cellRef)) { cellRef = cellRef[0]; }
                this.activateEditor(cellRef);
            } else if (this.editMode) {
                this.activateEditor();
            } else {
                this._currentIndexFocusDebounce = window.setTimeout(() => {
                    const item = this.getItemFromCell(cell);
                    if (item) {
                        this._dataGridControl.setCurrentIndex(item.index ?? cell.y);
                    }
                    this._currentIndexFocusDebounce = null;
                }, 500);
            }
        }));
        if (this.container) {
            this._cleanupTokens.push(addEventListener(this.container, 'keydown', this._onKeyDown.bind(this)));
            this._cleanupTokens.push(addEventListener(this.container, 'mousedown', this._onMouseDown.bind(this)));
            this._cleanupTokens.push(addEventListener(this.container, 'mouseup', this._onMouseUp.bind(this)));
            this._cleanupTokens.push(addEventListener(this.container, 'mouseover', this._onMouseDrag.bind(this)));
            this._cleanupTokens.push(addEventListener(this.container, 'contextmenu', this._onContextMenu.bind(this)));
            this._cleanupTokens.push(addEventListener(this.container, 'dblclick', this._onDoubleClick.bind(this)));
        }
    }

    /** Clean up all events and remove this extension from the grid control */
    destroy() {
        this._cleanupTokens.forEach(ct => ct());
        this._cleanupTokens = [];
        (this._dataGridControl as any)._navigation = undefined;
    }

    /** Register a keyboard shortuct on the navigation control */
    registerShortcut(pShortcut: KeyboardShortcut<[KeyboardShortcutUtils]>) {
        const uid = this._registeredShortcutUID;
        const mapCleanupTokens: (() => void)[] = [];
        pShortcut.keyCombos.forEach(combo => {
            const comboString = keyComboToString(combo);
            if (this._registeredShortcutMap.has(comboString)) {
                logger.warn(`${this._dataGridControl.id} keyboard shortcut is already registered, skipping`, combo);
            } else {
                this._registeredShortcutMap.set(comboString, uid);
                mapCleanupTokens.push(() => this._registeredShortcutMap.delete(comboString));
            }
        })
        if (mapCleanupTokens.length > 0) {
            this._registeredShortcuts.set(uid, pShortcut);
            this._registeredShortcutUID += 1;
            return () => {
                mapCleanupTokens.forEach(ct => ct());
                this._registeredShortcuts.delete(uid);
            };
        } else {
            return () => { };
        }
    }

    /** Render given area selection in the grid */
    renderSelection(pAreaSelection?: AreaSelection) {
        window.requestAnimationFrame(() => {
            this._selectionClassMap = undefined;
            if (pAreaSelection == null) { return; }
            const range = (start: number, stop: number, step = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
            const negativeCols = pAreaSelection.end.x - pAreaSelection.start.x < 0;
            const negativeRows = pAreaSelection.end.y - pAreaSelection.start.y < 0;

            const columns = range(pAreaSelection.start.x, pAreaSelection.end.x, negativeCols ? -1 : 1);
            const rows = range(pAreaSelection.start.y, pAreaSelection.end.y, negativeRows ? -1 : 1);

            const topRow = negativeRows ? rows[rows.length - 1] : rows[0];
            const bottomRow = negativeRows ? rows[0] : rows[rows.length - 1];
            const leftCol = negativeCols ? columns[columns.length - 1] : columns[0];
            const rightCol = negativeCols ? columns[0] : columns[columns.length - 1];
            const container = pAreaSelection.start.container;

            rows.forEach((row) => {
                columns.forEach((col) => {
                    let item: Partial<DataItemModel> | null | undefined = null;
                    if (container === GridSelectionContainer.Main) {
                        item = this._dataGridControl.dataObject ? this._dataGridControl.dataObject.data[row] : this._dataGridControl.props.data?.[row];
                    } else if (container === GridSelectionContainer.NewRecords) {
                        item = this._dataGridControl.dataObject?.batchData.data[row];
                    }
                    if (!item) { return; }

                    const classMap = ['o365-cell-range-selected'];
                    if (topRow === row) {
                        classMap.push('o365-cell-range-top')
                    }
                    if (bottomRow === row) {
                        classMap.push('o365-cell-range-bottom')
                    }
                    if (leftCol === col) {
                        classMap.push('o365-cell-range-left')
                    }
                    if (rightCol === col) {
                        classMap.push('o365-cell-range-right')
                    }

                    if (!this._selectionClassMap) { this._selectionClassMap = {}; }
                    if (!this._selectionClassMap[container]) { this._selectionClassMap[container] = {}; }
                    if (!this._selectionClassMap[container][row]) { this._selectionClassMap[container][row] = {}; }
                    if (classMap.length > 0) {
                        this._selectionClassMap[container][row][col] = classMap;
                    } else if (this._selectionClassMap[container][row][col].length > 0) {
                        delete this._selectionClassMap[container][row][col];
                    }
                });
            });
        });
    }

    /**
     * Try to focus the current active editor  
     * @param pCellRef Cell editor ref from which to start the traverse search for activation function.
     * If not provided the active cell editor ref will be used.
     */
    async activateEditor(pCellRef?: any) {
        this._editorActivated = false;
        // Make a copy of the active selection range and clear it. This range is set during the mousedown event and is used to set the editor selection range
        const selectionRange: [number, number] | undefined = this._editSelectionRange ? [...this._editSelectionRange] : undefined;
        this._editSelectionRange = undefined;
        await nextTick();
        // Helper function for focusing html input-esque elements and setting selection range for them
        const activateHTMLElement = async (pElement?: HTMLElement) => {
            if (pElement == null || pElement.type == 'file') { return; }
            if (pElement.tagName === 'TEXTAREA' && !this._dataGridControl.isTable) {
                await nextTick(); await nextTick(); // 1st tick is for poput init, 2nd for popper init
                // TODO: expand editor api to allow for init promises (thorugh inject/provide)
            }
            pElement.click();
            pElement.focus();
            if (selectionRange && (pElement as HTMLInputElement).setSelectionRange) {
                // Input and textarea range selection
                try {
                    (pElement as HTMLInputElement).setSelectionRange(selectionRange[0], selectionRange[1]);
                } catch (ex) {
                    // Not all input types support selection range, fail silently
                }
            } else if (selectionRange && pElement.isContentEditable && pElement.firstChild) {
                // Contenteditable range selection
                const selection = window.getSelection();
                if (selection == null) { return; }
                let startNode: Text | null = null;
                let endNode: Text | null = null;
                let charSum = 0;
                // Loop through all children nodes till startNode and endNode is found
                Array.from(pElement.childNodes).some((node) => {
                    if (node.nodeType === node.TEXT_NODE) {
                        charSum += (node as Text).length;
                        if (startNode == null) {
                            if (selectionRange[0] <= charSum) {
                                selectionRange[0] = selectionRange[0] - (charSum - (node as Text).length);
                                startNode = node as Text;
                            }
                        }
                        if (endNode == null) {
                            if (selectionRange[1] <= charSum) {
                                selectionRange[1] = selectionRange[1] - (charSum - (node as Text).length);
                                endNode = node as Text;
                            }
                        }
                    } else {
                        charSum += 1;
                        if (startNode == null) {
                            if (selectionRange[0] <= charSum) {
                                selectionRange[0] = 0;
                                startNode = node as Text;
                            }
                        }
                        if (endNode == null) {
                            if (selectionRange[1] <= charSum) {
                                selectionRange[1] = 0;
                                endNode = node as Text;
                            }
                        }
                    }
                    return startNode && endNode;
                });
                if (startNode == null || endNode == null) { return; }
                const range = document.createRange();
                range.setStart(startNode, selectionRange[0]);
                range.setEnd(endNode, selectionRange[1]);
                selection.removeAllRanges();
                selection.addRange(range);
            } else if (pElement.isContentEditable && pElement.lastChild) {
                // In cases where no selection range exists select the last node for contenteditable
                const lastTextNode = Array.from(pElement.childNodes).findLast(node => node.nodeType === node.TEXT_NODE) as Text;
                if (lastTextNode == null) { return; }
                const selection = window.getSelection();
                if (selection == null) { return; }
                const range = document.createRange();
                range.setStart(lastTextNode, lastTextNode.length);
                range.setEnd(lastTextNode, lastTextNode.length);
                selection.removeAllRanges();
                selection.addRange(range);
            }
            this._editorActivated = true;
        }
        const findFunction = (pReference?: HTMLElement | CellEditorRef): (CellEditorRef['activateEditor']) | null => {
            if (pReference == null) { return null; }
            if (this._isCellEditorRef(pReference)) {
                if (pReference.activateEditor) { return pReference.activateEditor; }
                else if (pReference.$refs?.popupRef) { return findFunction(pReference.$refs.popupRef); }
                else if (pReference.$refs?.editorRef) { return findFunction(pReference.$refs.editorRef); }
                else if (pReference.editorRef) { return findFunction(pReference.editorRef); }
                else if (pReference.$?.subTree?.component?.ctx) { return findFunction(pReference.$.subTree.component.ctx); }
            }
            else if (pReference?.nextElementSibling?.tagName === 'INPUT') { activateHTMLElement(pReference.nextElementSibling as HTMLHtmlElement); return null; }
            else if (pReference.parentElement) {
                let element = pReference.parentElement.querySelector('[data-grid-editor]') as HTMLElement;
                if (element) { activateHTMLElement(element); return null; }
                element = pReference.parentElement.querySelector('input') as HTMLElement;
                if (element) { activateHTMLElement(element); return null; }
                element = pReference.parentElement.querySelector('textarea') as HTMLElement;
                if (element) { activateHTMLElement(element); return null; }
                element = pReference.parentElement.querySelector('select') as HTMLElement;
                if (element) { activateHTMLElement(element); return null; }
                element = pReference.parentElement.querySelector('.o365-editor') as HTMLElement;
                if (element) { activateHTMLElement(element); return null; }
                element = pReference.parentElement.querySelector('[contenteditable]') as HTMLElement;
                if (element) { activateHTMLElement(element); return null; }
            }
            return null;
        }

        const activateFn = findFunction(pCellRef ? pCellRef : this.cellEditorRef);
        if (activateFn) {
            activateFn({
                selectionRange: selectionRange
            });
            this._editorActivated = true;
        }
    }

    /** Get cell location from string */
    getCellFromString(pString: string): Cell | null {
        try {
            const cell = pString.split('_');
            return {
                container: cell[0] as GridSelectionContainer,
                x: parseInt(cell[1]),
                y: parseInt(cell[2]),
            }
        } catch (ex) {
            return null;
        }
    }

    /** Convert cell location to string */
    cellToString(pCell: Cell) {
        return `${pCell.container}_${pCell.x}_${pCell.y}`;
    }

    /** Set focus to cell location */
    setFocus(pCell: Cell | null) {
        if (pCell == null || (pCell.container != GridSelectionContainer.NewRecords && pCell.container !== GridSelectionContainer.Main)) {
            this.exitEditMode();
        }
        this._activeCell = pCell ? this._copyCell(pCell) : null;

    }


    /** Scroll to the given row index. Row must be from the main list. This will not work on new records */
    scrollToRow(pIndex: number) {
        const cell: Cell = {
            x: this._activeCell?.x ?? 0,
            y: pIndex,
            container: GridSelectionContainer.Main
        };
        this.scrollToCell(cell);
    }

    /**
     * Focus the first editable cell of a given row index. THe default container is
     * the new records panel
     */
    focusFirstEditableCell(pRow: number, pContainer = GridSelectionContainer.NewRecords) {
        const cell = this.getFirstRowCell(pRow, pContainer, true);
        if (cell) {
            this.setSingleCellSelection(cell);;
            this.setFocus(cell);
        }
    };

    /** Set area selection to cell location */
    setSelection(pStart: Cell, pEnd: Cell) {
        if (pStart.container !== pEnd.container) { return; }
        switch (pStart.container) {
            case GridSelectionContainer.Header:
            case GridSelectionContainer.Filter:
            case GridSelectionContainer.Summary:
                return;
            default:
                this._activeSelection = {
                    start: pStart,
                    end: pEnd
                };
        }
    }

    /** Set selection to a cell */
    setSingleCellSelection(pCell: Cell) {
        this._activeSelection = {
            start: this._copyCell(pCell),
            end: this._copyCell(pCell)
        }
    }

    /**
     * Set selection end cell. If no selection exists then
     * will set it as single cell selection
     */
    setSelectionEnd(pCell?: Cell) {
        if (pCell == null && this.activeSelection?.start) {
            this._activeSelection = {
                start: this._copyCell(this.activeSelection.start),
                end: this._copyCell(this.activeSelection.start),
            }
        } else if (pCell) {
            this._activeSelection = {
                start: this._copyCell(this.activeSelection?.start ?? pCell),
                end: this._copyCell(pCell),
            }
        } else {
            this._activeSelection = null;
        }
    }

    /** Activate edit mode on the current focused cell */
    enterEditMode(pOptions?: {
        selectionRange?: [number, number]
    }) {
        // added if statment if pressed any other button while highlihting pressed click 2024-07-01
        if (!this._dataGridControl.state.allowUpdate && !this._dataGridControl.state.allowInsert && !this._dataGridControl.props.editable) { return; }
        const cell = this._activeCell;
        if (cell) {
            const column = this._dataGridControl.dataColumns.columns[cell.x];
            if (!column || !this.isEditable(cell) || column.hide) { return; }
            const item = this.getItemFromCell(cell);
            if (!item || (item as NodeItem).isSummaryItem || (item as NodeItem).o_groupHeaderRow) { return; }
            this._dataGridControl.setCurrentIndex(item.index!);
            this._editSelectionRange = pOptions?.selectionRange;
            this._editMode = true;
        }
    }

    /** Exit edit mode */
    exitEditMode() {
        if (!this._editMode) { return; }
        this._editMode = false;
    }

    /** Clear area selection */
    clearSelection() {
        this._activeSelection = null;
    }

    /** Clear focus */
    clearFocus() {
        this._activeCell = null;
    }

    /**
     * Try to reset focus based on current cell. Used when data is reloaded
     * to keep focus in the grid
     */
    resolveFocus(pOptions?: {
        clearFocus?: boolean
    }) {
        const cell = this._activeCell;
        if (cell == null) { return; }
        switch (cell.container) {
            case GridSelectionContainer.Filter:
            case GridSelectionContainer.Header:
                if (pOptions?.clearFocus) {
                    this.clearFocus();
                }
                break;
            case GridSelectionContainer.NewRecords:
                const newCell = this.getFirstRowCell(0, GridSelectionContainer.Main);
                if (newCell) {
                    this.setFocus(newCell);
                } else {
                    this.clearFocus();
                }
                break;
            default:
                this.clearFocus();
                break;
        }
    }

    //--- Grid event handlers ---
    private _onKeyDown(pEvent: KeyboardEvent) {

        const navigationKey = keyComboToString(pEvent);
        if (this._registeredShortcutMap.has(navigationKey) && !this._editMode) {
            const uid = this._registeredShortcutMap.get(navigationKey)!;
            const shortcut = this._registeredShortcuts.get(uid);
            if (shortcut?.action(pEvent, this._getKeyboardShortcutUtils())) {
                return;
            }
        }

        const key = pEvent.key;
        switch (key) {
            case KeyCode.ENTER:
                this._onEnterKeyDown(pEvent);
                break;
            case KeyCode.F2:
                this._onF2KeyDown(pEvent);
                break;
            case KeyCode.ESC:
                this._onEscKeyDown(pEvent);
                break;
            case KeyCode.TAB:
                this._onTabKeyDown(pEvent);
                break;
            case KeyCode.BACKSPACE:
                this._onBackspaceDown(pEvent);
                break;
            case KeyCode.DEL:
                this._onDelKeyDown(pEvent);
                break;
            case KeyCode.SPACE:
                this._onSpaceKeyDown(pEvent);
                break;
            case KeyCode.DOWN:
            case KeyCode.UP:
            case KeyCode.LEFT:
            case KeyCode.RIGHT:
                this._handleNavigationKeyDown(pEvent);
                break;
            default:
                this._handleDefaultKeyDown(pEvent);
        }

    }

    private _onDoubleClick(pEvent: MouseEvent) {
        if (pEvent.button !== 0) { return; }
        const closest = this._getClosestCellFromEvent(pEvent);
        if (closest && !closest.classList.contains('o365-editor-cell')) {
            const cell = this._getLocationFromElement(closest);
            switch (cell?.container) {
                case GridSelectionContainer.Main:
                case GridSelectionContainer.NewRecords:
                    break;
                case GridSelectionContainer.Filter:
                case GridSelectionContainer.Summary:
                case GridSelectionContainer.Header:
                default:
                    return;
            }

            const column = this._dataGridControl.dataColumns.columns[cell.x];
            let skipEdit = false;
            if ((pEvent.target as HTMLElement).closest('[data-grid-ignore-edit]')) {
                skipEdit = true;
            }
            if (!skipEdit && column && !column.singleClickEdit && !pEvent.ctrlKey && !pEvent.shiftKey) {
                const item = this.getItemFromCell(cell);
                let cellIsEditable = false;
                if (item == null) {
                    cellIsEditable = false;
                } else if (typeof column.editable === 'function') {
                    cellIsEditable = column.editable(item);
                } else {
                    cellIsEditable = column.editable;
                }

                if (item && (item as NodeItem).isSummaryItem) {
                    cellIsEditable = false;
                }

                if (cellIsEditable) {
                    this.enterEditMode();
                } else if (this.editMode) {
                    this.exitEditMode();
                }
            }
        }
    }

    private _onMouseDown(pEvent: MouseEvent) {
        if (pEvent.button !== 0) { return; }
        const closest = this._getClosestCellFromEvent(pEvent);
        this._mouseDownElement = closest ?? undefined;
        if (closest && !closest.classList.contains('o365-editor-cell')) {
            const cell = this._getLocationFromElement(closest);
            switch (cell?.container) {
                case GridSelectionContainer.Filter:
                case GridSelectionContainer.Summary:
                    this.setFocus(cell);
                    return;
                case GridSelectionContainer.Main:
                case GridSelectionContainer.NewRecords:
                    break;
                case GridSelectionContainer.Header:
                default:
                    return;
            }
            const suppressedOrders = this._getSelectionSuppressedColumns();
            if (suppressedOrders.has(cell.x)) {
                if (this.editMode) {
                    this.exitEditMode();
                }
                return;
            }
            if (pEvent.shiftKey) {
                this.setSelectionEnd(cell);
            } else {
                this.setSelection(cell, cell);
            }
            this.setFocus(cell);

            const column = this._dataGridControl.dataColumns.columns[cell.x];
            if (column && column.singleClickEdit && !pEvent.ctrlKey && !pEvent.shiftKey) {
                const item = this.getItemFromCell(cell);
                let cellIsEditable = false;
                if (item == null) {
                    cellIsEditable = false;
                } else if (typeof column.editable === 'function') {
                    cellIsEditable = column.editable(item);
                } else {
                    cellIsEditable = column.editable;
                }

                if (item && (item as NodeItem).isSummaryItem) {
                    cellIsEditable = false;
                }

                if (cellIsEditable && (pEvent.target as HTMLElement).closest('[data-grid-ignore-edit]')) {
                    cellIsEditable = false;
                }

                if (cellIsEditable) {
                    if (item) {
                        this._dataGridControl.setCurrentIndex(item.index!);
                    }
                    if (this.editMode) {
                        this.exitEditMode();
                    }
                    if (this.container) {
                        // let originX = pEvent.screenX;
                        // let originY = pEvent.screenY;

                        const debounce = setTimeout(() => {
                            this._mouseUpOperation = undefined;
                        }, 250);
                        this._ctMouseMove = () => {
                            clearTimeout(debounce);
                        }
                        // this._ctMouseMove = addEventListener(this.container, 'mousemove', ({ screenX, screenY }) => {
                        //     if (Math.abs(originX - screenX) > 10 || Math.abs(originY - screenY) > 15) {
                        //         this._mouseUpOperation = undefined;
                        //     }
                        // }, { passive: true });
                    }
                    this._mouseUpOperation = () => nextTick().then(() => {
                        window.setTimeout(() => {
                            let selectionRange: [number, number] | undefined = undefined;
                            try {
                                const selection = window.getSelection();
                                if (selection?.anchorNode?.nodeType === window.Node.TEXT_NODE) {
                                    selectionRange = [selection.anchorOffset, selection.anchorOffset];
                                }
                            } catch (_ex) {
                                // skip setting selection range
                            }
                            this.enterEditMode({
                                selectionRange: selectionRange
                            });
                        }, 10);
                    });
                } else if (this.editMode) {
                    this.exitEditMode();
                }
            } else if (this.editMode) {
                this.exitEditMode();
            }

            if (pEvent.ctrlKey) {
                if ((pEvent.target as HTMLElement).tagName !== 'SELECT' && (pEvent.target as HTMLElement).tagName !== 'INPUT' && (pEvent.target as HTMLElement).tagName !== 'TEXTAREA') {
                    pEvent.preventDefault();
                }
                this._isDragging = true;
                return false;
            } else {
                this._focusedThroughClick = true;
                window.setTimeout(() => {
                    this._focusedThroughClick = false;
                }, 50);
            }
        }
        return;
    }

    private _onMouseUp(_pEvent: MouseEvent) {
        this._isDragging = false;
        if (!this._wasDragging && this._mouseUpOperation) {
            this._mouseUpOperation();
        }
        this._mouseUpOperation = undefined;;
        this._wasDragging = false;
        if (this._ctMouseMove) {
            this._ctMouseMove();
            this._ctMouseMove = undefined;
        }
        if (this._mouseDownElement) {
            window.requestAnimationFrame(() => {
                this._mouseDownElement = undefined;
            });
        }
    }

    private _onMouseDrag(pEvent: MouseEvent) {
        // this._mouseUpOperation = undefined;
        if (this._isDragging) {
            const closest = this._getClosestCellFromEvent(pEvent);
            if (closest && !closest.classList.contains('o365-editor-cell')) {
                pEvent.preventDefault();
                this._wasDragging = true;
                const cell = this._getLocationFromElement(closest);
                if (cell == null) { return; }

                const suppressedOrders = this._getSelectionSuppressedColumns();

                const isPositiveSelection = (this.activeSelection?.start.x ?? 0) <= cell.x;
                const startX = this.activeSelection?.start.x ?? 0;
                const skipSelection = Object.values(suppressedOrders).some(order => {
                    const isSuppressed = isPositiveSelection
                        ? startX < order && cell.x >= order
                        : startX > order && cell.x <= order;
                    return isSuppressed;
                });
                if (skipSelection) {
                    if (this.activeSelection?.end != null) {
                        this.setSelectionEnd({
                            x: this.activeSelection.end.x,
                            y: cell.y,
                            container: cell.container
                        })
                    }
                    return;
                }
                this.setSelectionEnd(cell);
            }
        }
    }

    private async _onContextMenu(pEvent: MouseEvent) {
        if (pEvent.ctrlKey) { return; }
        const closest = this._getClosestCellFromEvent(pEvent);

        if (!closest || closest.classList.contains('o365-editor-cell')) {
            if (this.contextMenuRef?.dropdown.isOpen) {
                this.contextMenuRef.dropdown.close();
            }
            return;
        } else if (closest.getAttribute('o365-field') === 'o365_Action') {
            return;
        }

        const cell = this._getLocationFromElement(closest);
        if (cell == null || ![GridSelectionContainer.Main, GridSelectionContainer.NewRecords].includes(cell.container)) { return; }

        pEvent.preventDefault();
        const hasRangeSelection = !!this.activeSelection && !this.isSingleSelection;
        let resetSelection = !hasRangeSelection;
        if (hasRangeSelection) {
            resetSelection = !this._locationIsInSelection(cell)
        }
        if (resetSelection) {
            this.setSingleCellSelection(cell);
            this.setFocus(cell);

            if (this.editMode) {
                this.exitEditMode();
            }
        }
        if (this.contextMenuRef?.dropdown?.isOpen) {
            await this.contextMenuRef.dropdown.close();
        }

        const item = this.getItemFromCell(cell);
        if (item) {
            this._dataGridControl.setCurrentIndex(item.index ?? cell.y);
        }

        this.contextMenuRef?.initItemValues({
            column: this._dataGridControl.dataColumns.columns[cell.x],
            row: item as DataItemModel,
            rowIndex: cell.y,
            event: pEvent
        });
        this.contextMenuRef?.setLocation(pEvent.x, pEvent.y);
        this.contextMenuRef?.dropdown.open();
    }

    //--- Cell validators ---

    /**
     * Check if a cell's x coordinate is valid. When in edit mode will check
     * if the cell is editable
     */
    isValidX(pCell: Cell) {
        const column = this._dataGridControl.dataColumns.columns[pCell.x];
        if (column == null) { return false; }
        if (this.editMode && !this.isEditable(pCell)) { return false; }
        return !column.hide && !column.suppressNavigable;
    }

    /**
     * Check if a cell's y coordinate is valid. When in edit mode will check
     * if the cell is editable
     */
    isValidY(pCell: Cell, pSKipEditCheck = false) {
        const dataObject = this._dataGridControl.dataObject;
        const isTable = this._dataGridControl.isTable;
        if (!pSKipEditCheck && this.editMode && !this.isEditable(pCell)) { return false; }
        switch (pCell.container) {
            case GridSelectionContainer.Header:
                return pCell.y === 0;
            case GridSelectionContainer.NewRecords:
                if (dataObject && dataObject.batchDataEnabled) {
                    return dataObject.batchData.data[pCell.y] != null;
                } else {
                    return false;
                }
            case GridSelectionContainer.Filter:
                return pCell.y === 0;
            case GridSelectionContainer.Main:
                if (dataObject) {
                    if (isTable && dataObject.hasPagedData) {
                        const y = pCell.y + dataObject.pagedData.currentIndex;
                        return y >= dataObject.pagedData.currentIndex && y <= dataObject.pagedData.lastIndex;
                    } else {
                        return dataObject.data[pCell.y] != null;
                    }
                } else if (this._dataGridControl.props.data) {
                    return this._dataGridControl.props.data[pCell.y] != null;
                } else {
                    return false;
                }
            case GridSelectionContainer.Summary:
                return pCell.y === 0;
        }
    }

    /** Check if a cell is editable */
    isEditable(pCell: Cell) {
        switch (pCell.container) {
            case GridSelectionContainer.Main:
            case GridSelectionContainer.NewRecords:
                const column = this._dataGridControl.dataColumns.columns[pCell.x];
                if (column == null) { return false; }
                const item = this.getItemFromCell(pCell);
                if (item == null || (item as NodeItem).isSummaryItem) { return false; }
                if (typeof column.editable === 'function') {
                    return column.editable(item);
                } else {
                    return column.editable;
                }
            default:
                return false;
        }
    }

    //--- Valid cell locators ---

    /** Returns closest valid cell from above in the same container */
    getNextValidCellAbove(pCell: Cell) {
        const newCell = this._copyCell(pCell);
        newCell.y -= 1;

        switch (pCell.container) {
            case GridSelectionContainer.Header:
                return this.isValidY(newCell) ? newCell : null;
            case GridSelectionContainer.NewRecords:
            case GridSelectionContainer.Main:
                while (newCell.y >= 0) {
                    const isValid = this.isValidY(newCell);
                    if (isValid) {
                        return newCell;
                    } else {
                        newCell.y -= 1;
                    }
                }
                return null;
            case GridSelectionContainer.Filter:
                return this.isValidY(newCell) ? newCell : null;
            case GridSelectionContainer.Summary:
                return this.isValidY(newCell) ? newCell : null;
        }
    }

    /** Returns closest valid cell from above in the same container */
    getNextValidCellBelow(pCell: Cell) {
        const newCell = this._copyCell(pCell);
        newCell.y += 1;
        const dataObject = this._dataGridControl.dataObject;

        switch (pCell.container) {
            case GridSelectionContainer.Header:
                return this.isValidY(newCell) ? newCell : null;
            case GridSelectionContainer.NewRecords:
                if (!dataObject || !dataObject.batchDataEnabled) { return null; }
                while (newCell.y < dataObject.batchData.data.length) {
                    const isValid = this.isValidY(newCell);
                    if (isValid) {
                        return newCell;
                    } else {
                        newCell.y += 1;
                    }
                }
                return null;
            case GridSelectionContainer.Filter:
                return this.isValidY(newCell) ? newCell : null;
            case GridSelectionContainer.Main:
                const dataArray = dataObject ? dataObject.data : this._dataGridControl.props.data;
                if (dataArray == null) { return null; }
                while (newCell.y < dataArray.length) {
                    const isValid = this.isValidY(newCell);
                    if (isValid) {
                        return newCell;
                    } else {
                        newCell.y += 1;
                    }
                }
                return null;
            case GridSelectionContainer.Summary:
                return this.isValidY(newCell) ? newCell : null;
        }
    }

    /** Returns closest valid cell from the right in the same container */
    getNextValidCellToTheRight(pCell: Cell) {
        const newCell = this._copyCell(pCell);
        newCell.x += 1;
        const columns = this._dataGridControl.dataColumns.columns;
        while (newCell.x < columns.length) {
            const isValid = this.isValidX(newCell);
            if (isValid) {
                return newCell;
            }
            newCell.x += 1;
        }
        return null;
    }

    /** Returns closest valid cell from the left in the same container */
    getNextValidCellToTheLeft(pCell: Cell) {
        const newCell = this._copyCell(pCell);
        newCell.x -= 1;
        while (newCell.x >= 0) {
            const isValid = this.isValidX(newCell);
            if (isValid) {
                return newCell;
            }
            newCell.x -= 1;
        }
        return null;
    }

    /** Returns the first focusable cell of the given cell's y in the same container*/
    getMaxValidCellToTheLeft(pCell: Cell) {
        const newCell = this._copyCell(pCell);
        const columns = this._dataGridControl.dataColumns.columns;
        for (let i = 0; i < columns.length; i++) {
            newCell.x = i;
            if (this.isValidX(newCell)) {
                return newCell;
            }
        }
        return null;
    }

    /** Returns the last focusable cell of the given cell's y in the same container*/
    getMaxValidCellToTheRight(pCell: Cell) {
        const newCell = this._copyCell(pCell);
        const columns = this._dataGridControl.dataColumns.columns;
        for (let i = columns.length - 1; i > pCell.x; i--) {
            newCell.x = i;
            if (this.isValidX(newCell)) {
                return newCell;
            }
        }
        return null;
    }

    /** Returns the first focusable cell of the given cell's x in the same container*/
    getMaxValidCellAbove(pCell: Cell) {
        const newCell = this._copyCell(pCell);
        for (let i = 0; i < pCell.y; i++) {
            newCell.y = i;
            if (this.isValidY(newCell)) {
                return newCell;
            }
        }
        return null;
    }

    /** Returns the last focusable cell of the given cell's x in the same container*/
    getMaxValidCellBelow(pCell: Cell) {
        const newCell = this._copyCell(pCell);
        const getMaxForGridList = (pNewRecords = false) => {
            let maxIndex = null;
            if (pNewRecords) {
                maxIndex = this._dataGridControl.dataObject?.batchData.data.length;
            } else {
                maxIndex = this._dataGridControl.dataObject
                    ? this._dataGridControl.dataObject.data.length
                    : this._dataGridControl.props.data?.length;
            }
            maxIndex = (maxIndex ?? 0) - 1;

            for (let i = maxIndex; i > pCell.y; i--) {
                newCell.y = i;
                if (this.isValidY(newCell)) {
                    return newCell;
                }
            }
            return null;
        };

        switch (pCell.container) {
            case GridSelectionContainer.Header:
            case GridSelectionContainer.Filter:
            case GridSelectionContainer.Summary:
                return null;
            case GridSelectionContainer.Main:
                return getMaxForGridList();
            case GridSelectionContainer.NewRecords:
                return getMaxForGridList(true);
            default:
                return null;
        }
    }

    /** Get first valid cell of a row in the given container */
    getFirstRowCell(pRow: number, pContainer = GridSelectionContainer.Main, editableOnly = false) {
        const columns = this._dataGridControl.dataColumns.columns;
        for (let i = 0; i < columns.length; i++) {
            const cell: Cell = { x: i, y: pRow, container: pContainer };
            if (!this.isValidY(cell, true)) {
                return null;
            }
            const column = columns[i];
            if (!column.hide && !column.suppressNavigable && ((!this.editMode && !editableOnly) || (this.editMode && this.isEditable(cell)) || (editableOnly && this.isEditable(cell)))) {
                return cell;
            }
        }
        return null;
    }

    /** Get last valid cell of a row in the given container  */
    getLastRowCell(pRow: number, pContainer = GridSelectionContainer.Main, editableOnly = false) {
        const columns = this._dataGridControl.dataColumns.columns;
        for (let i = columns.length - 1; i >= 0; i--) {
            const cell: Cell = { x: i, y: pRow, container: pContainer };
            if (!this.isValidY(cell, true)) {
                return null;
            }
            const column = columns[i];
            if (!column.hide && !column.suppressNavigable && ((!this.editMode && !editableOnly) || (this.editMode && this.isEditable(cell)) || (editableOnly && this.isEditable(cell)))) {
                return cell;
            }
        }
        return null;
    }

    /** Get last row index of a container */
    getLastRowIndex(pContainer: GridSelectionContainer) {
        switch (pContainer) {
            case GridSelectionContainer.Header:
                return 0;
            case GridSelectionContainer.NewRecords:
                return (this._dataGridControl.dataObject?.batchData.data.length || 1) - 1;
            case GridSelectionContainer.Filter:
                return 0;
            case GridSelectionContainer.Main:
                if (this._dataGridControl.dataObject) {
                    return (this._dataGridControl.dataObject.data.length || 1) - 1;;
                } else if (this._dataGridControl.props.data) {
                    return (this._dataGridControl.props.data.length || 1) - 1;
                } else {
                    return null
                }
            case GridSelectionContainer.Summary:
                return 0;
        }
    }

    /** Get item value from a cell */
    getItemFromCell(pCell: Cell) {
        switch (pCell.container) {
            case GridSelectionContainer.NewRecords:
                if (this._dataGridControl.dataObject && this._dataGridControl.dataObject.batchDataEnabled) {
                    return this._dataGridControl.dataObject.batchData.data[pCell.y];
                } else {
                    return undefined;
                }
                return;
            case GridSelectionContainer.Main:
                if (this._dataGridControl.dataObject) {
                    return this._dataGridControl.dataObject.data[pCell.y];
                } else {
                    return this._dataGridControl.props.data?.[pCell.y];
                }
            default:
                return undefined;
        }
    }


    //--- Navigation ---

    /** Navigate upwards from the given or active cell. Will jump containers if possible */
    navigateUp(pModifiers: NavigationModifiers, pCell?: Cell) {
        if (pCell == null) {
            pCell = this._activeCell ?? undefined;
        }

        if (pCell == null) { return; }

        const nextCell = pModifiers.navigateMax
            ? this.getMaxValidCellAbove(pCell)
            : this.getNextValidCellAbove(pCell);
        if (nextCell) {
            this.setFocus(nextCell);
            if (pModifiers.appendArea && this._activeSelection?.start) {
                this.setSelection(this._activeSelection.start, nextCell);
            } else {
                this.setSelection(nextCell, nextCell);
            }
        } else if (!pModifiers.navigateMax) {
            // No valid cell above in current container, try to jump containers
            const containersOrder = this._containersRenderOrder;
            const currentContainerIndex = containersOrder.findIndex(x => x == pCell!.container);
            const containerAbove = containersOrder[currentContainerIndex - 1];
            if (containerAbove) {
                const rowIndex = this.getLastRowIndex(containerAbove);
                if (rowIndex != null) {
                    this.navigateUp(pModifiers, {
                        container: containerAbove,
                        x: pCell.x,
                        y: rowIndex + 1,
                    });
                }
            }
        }
    }

    /** Navigate downwards from the given or active cell. Will jump containers if possible */
    navigateDown(pModifiers: NavigationModifiers, pCell?: Cell) {
        if (pCell == null) {
            pCell = this._activeCell ?? undefined;
        }

        if (pCell == null) { return; }

        const nextCell = pModifiers.navigateMax
            ? this.getMaxValidCellBelow(pCell)
            : this.getNextValidCellBelow(pCell);
        if (nextCell) {
            this.setFocus(nextCell);
            if (pModifiers.appendArea && this._activeSelection?.start) {
                this.setSelection(this._activeSelection.start, nextCell);
            } else {
                this.setSelection(nextCell, nextCell);
            }
        } else if (!pModifiers.navigateMax) {
            // No valid cell above in current container, try to jump containers
            const containersOrder = this._containersRenderOrder;
            const currentContainerIndex = containersOrder.findIndex(x => x == pCell!.container);
            const containerBellow = containersOrder[currentContainerIndex + 1];
            if (containerBellow) {
                const rowIndex = 0;
                if (rowIndex != null) {
                    this.navigateUp(pModifiers, {
                        container: containerBellow,
                        x: pCell.x,
                        y: rowIndex + 1,
                    });
                }
            }
        }
    }

    /** Navigate left from the given or active cell */
    navigateLeft(pModifiers: NavigationModifiers, pCell?: Cell) {
        if (pCell == null) {
            pCell = this._activeCell ?? undefined;
        }

        if (pCell == null) { return; }

        const nextCell = pModifiers.navigateMax
            ? this.getMaxValidCellToTheLeft(pCell)
            : this.getNextValidCellToTheLeft(pCell);
        if (nextCell) {
            if (pModifiers.appendArea && nextCell.container === GridSelectionContainer.Header) {
                if (this.moveHeaderCell(pCell, nextCell)) {
                    this.setFocus(nextCell);
                }
            } else if (pModifiers.appendArea && this._activeSelection?.start) {
                this.setSelection(this._activeSelection.start, nextCell);
                this.setFocus(nextCell);
            } else {
                this.setSelection(nextCell, nextCell);
                this.setFocus(nextCell);
            }
        }

    }

    /** Navigate right from the given or active cell */
    navigateRight(pModifiers: NavigationModifiers, pCell?: Cell) {
        if (pCell == null) {
            pCell = this._activeCell ?? undefined;
        }

        if (pCell == null) { return; }

        const nextCell = pModifiers.navigateMax
            ? this.getMaxValidCellToTheRight(pCell)
            : this.getNextValidCellToTheRight(pCell);
        if (nextCell) {
            if (pModifiers.appendArea && nextCell.container === GridSelectionContainer.Header) {
                if (this.moveHeaderCell(pCell, nextCell)) {
                    this.setFocus(nextCell);
                }
            } else if (pModifiers.appendArea && this._activeSelection?.start) {
                this.setSelection(this._activeSelection.start, nextCell);
                this.setFocus(nextCell);
            } else {
                this.setSelection(nextCell, nextCell);
                this.setFocus(nextCell);
            }
        }
    }

    /** Re-order grid columns */
    moveHeaderCell(pFrom: Cell, pTo: Cell) {
        if (pFrom.container !== GridSelectionContainer.Header || pTo.container !== GridSelectionContainer.Header) { return false; }
        const column = this._dataGridControl.dataColumns.columns[pFrom.x];
        if (column == null || column.suppressMovable) { return false; }
        const toColumn = this._dataGridControl.dataColumns.columns[pTo.x];
        if (toColumn == null || toColumn.colId.startsWith('o365_')) { return false; }
        if (column.pinned !== toColumn.pinned) { return false; }
        this._dataGridControl.dataColumns.setColumnOrder(column, pTo.x);
        return true;
    }

    /** Scroll to the given cell */
    async scrollToCell(pCell: Cell) {
        let buffer = 0;
        if (this._dataGridControl.isTable) {
            if (this._dataGridControl.dataObject?.hasPagedData) {
                buffer = this._dataGridControl.dataObject.pagedData.lastIndex - this._dataGridControl.dataObject.pagedData.currentIndex;
            }
        }
        switch (pCell.container) {
            case GridSelectionContainer.NewRecords:
            case GridSelectionContainer.Main:
                const containerEl = this.container?.querySelector(`[data-o365-container="${pCell.container}"]`);
                if (containerEl) {
                    const rowHeight = this._getRowHeight(pCell.y);
                    const cellY = (buffer + pCell.y) * rowHeight;

                    if ((containerEl.clientHeight + containerEl.scrollTop) <= (cellY + rowHeight)) {
                        containerEl.scrollTop = cellY - containerEl.clientHeight + rowHeight - 1;
                    } else if (cellY < containerEl.scrollTop) {
                        containerEl.scrollTop = cellY === 0 ? 1 : cellY;
                    }
                    await nextTick();
                }
                return;
            default:
                return;
        }
    }

    getCellInnerText(pCell: Cell) {
        const el = this._getCellElement(pCell);
        return el?.innerText;
    }

    /** Create cell location copy */
    private _copyCell(pCell: Cell) {
        return {
            container: pCell.container,
            x: pCell.x,
            y: pCell.y
        };
    }

    private _onDelKeyDown(pEvent: KeyboardEvent) {
        if (this.editMode) { return; }
        const cell = this._activeCell;
        switch (cell?.container) {
            case GridSelectionContainer.Main:
            case GridSelectionContainer.NewRecords:
                if (cell.x == 0 && this._dataGridControl.state.allowDelete) {
                    this._deleteItem(cell, pEvent);
                    return;
                }

                if (!this.isEditable(cell)) { return; }
                const item = this.getItemFromCell(cell);
                const column = this._dataGridControl.dataColumns.columns[cell.x];
                if (column && item) {
                    pEvent.preventDefault();
                    this._dataGridControl.setCurrentIndex(item.index!);
                    (item as any)[column.field] = null;
                }
                return;
            case GridSelectionContainer.Filter:
                return;
            default:
                return;
        }
    }

    private _onBackspaceDown(pEvent: KeyboardEvent) {
        if (this.editMode) { return; }
        const cell = this._activeCell;
        switch (cell?.container) {
            case GridSelectionContainer.Main:
            case GridSelectionContainer.NewRecords:
                if (!this.isEditable(cell)) { return; }
                const item = this.getItemFromCell(cell);
                const column = this._dataGridControl.dataColumns.columns[cell.x];
                if (column && item) {
                    pEvent.preventDefault();
                    this.enterEditMode();
                    (item as any)[column.field] = null;
                }
                return;
            case GridSelectionContainer.Filter:
                return;
            default:
                return;
        }
    }

    private _onEnterKeyDown(pEvent: KeyboardEvent) {
        const cell = this.activeCell;
        switch (cell?.container) {
            case GridSelectionContainer.Main:
            case GridSelectionContainer.NewRecords:
                const backwards = pEvent.shiftKey;
                const nextCell = backwards
                    ? this.getNextValidCellAbove(cell)
                    : this.getNextValidCellBelow(cell);
                if (nextCell != null) {
                    let waitForSave = false;
                    if (this.editMode) {
                        if (!this._dataGridControl.state.disableSaveOncurrentIndexChange) {
                            waitForSave = true;
                            if (cell.container === GridSelectionContainer.Main) {
                                this.exitEditMode();
                            }
                            pEvent.preventDefault();
                            this._dataGridControl.save().then(() => {
                                this.setFocus(nextCell);
                                this.setSingleCellSelection(nextCell);
                            });
                        }
                    }
                    if (!waitForSave) {
                        pEvent.preventDefault();
                        this.setFocus(nextCell);
                        this.setSingleCellSelection(nextCell);
                    }
                    return;
                } else {
                    const containersOrder = this._containersRenderOrder;
                    const currentContainerIndex = containersOrder.findIndex(x => x == cell!.container);
                    const nextContainer = containersOrder[currentContainerIndex + (backwards ? -1 : 1)];
                    if (nextContainer && (nextContainer === GridSelectionContainer.Main || nextContainer === GridSelectionContainer.NewRecords)) {
                        const rowIndex = backwards
                            ? this.getLastRowIndex(nextContainer)
                            : 0;
                        if (rowIndex != null) {
                            const newCell = this.getNextValidCellBelow({
                                container: nextContainer,
                                x: cell.x,
                                y: rowIndex - 1
                            })
                            if (newCell) {
                                pEvent.preventDefault();
                                this.setFocus(newCell);
                                this.setSingleCellSelection(newCell);
                            }
                        }
                    }
                }

                if (this.editMode) {
                    pEvent.preventDefault();
                    this.exitEditMode();
                    if (cell) {
                        const cellElement = this._getCellElement(cell);
                        cellElement?.focus();
                    }
                }
                return;
            case GridSelectionContainer.Header:
                if (this._shouldSkipEventHandler(cell)) { return; }
                pEvent.preventDefault();
                const headerColumn = this._dataGridControl.dataColumns.columns[cell.x];
                if (headerColumn == null) { return; }
                if (headerColumn.colId === 'o365_System') {
                    this._getCellElement(cell)?.querySelector('button')?.click();
                } else if (headerColumn.colId === 'o365_MultiSelect') {
                    this._getCellElement(cell)?.querySelector('input')?.click();
                } else if (headerColumn.sortable) {
                    this._dataGridControl.header?.setSort(headerColumn.colId, undefined, pEvent.ctrlKey);
                }
                return;
            case GridSelectionContainer.Filter:
                if (this._shouldSkipEventHandler(cell)) { return; }
                pEvent.preventDefault();
                const element = this._getCellElement(cell);
                element?.querySelector('input')?.focus();
                return;
            default:
                return;
        }
    }

    private _onF2KeyDown(_pEvent: KeyboardEvent) {
        const cell = this.activeCell;
        if (cell != null) {
            switch (cell.container) {
                case GridSelectionContainer.Main:
                case GridSelectionContainer.NewRecords:
                    if (this.editMode) {
                        this.exitEditMode();
                        const cellElement = this._getCellElement(cell);
                        cellElement?.focus();
                    } else {
                        this.enterEditMode();
                    }
                    return;
                default:
                    return;
            }
        }
    }
    private _onEscKeyDown(_pEvent: KeyboardEvent) {
        const cell = this.activeCell;
        switch (cell?.container) {
            case GridSelectionContainer.Main:
            case GridSelectionContainer.NewRecords:
                const column = this._dataGridControl.dataColumns.columns[cell.x];
                let field: string | null = null;
                if (this.editMode && column && !column.unbound) {
                    field = column.field
                }
                const item = this.getItemFromCell(cell);
                if (item?.hasChanges) {
                    this._dataGridControl.cancelChanges(item.index!, field ?? undefined);
                }
                if (this.editMode) {
                    this.exitEditMode();
                    if (cell) {
                        const cellElement = this._getCellElement(cell);
                        cellElement?.focus();
                    }
                }
                return;
            case GridSelectionContainer.Filter:
                if (this._editMode) {
                    this._editMode = false;
                }
                const cellElement = this._getCellElement(cell);
                cellElement?.focus();
                return;
            default:
                return;
        }
    }
    private _onTabKeyDown(pEvent: KeyboardEvent) {
        const cell = this.activeCell;
        if (cell == null) { return; }
        pEvent.preventDefault();
        const backwards = pEvent.shiftKey;
        let nextCell = backwards
            ? this.getNextValidCellToTheLeft(cell)
            : this.getNextValidCellToTheRight(cell);

        if (nextCell == null) {
            nextCell = backwards
                ? this.getLastRowCell(cell.y - 1, cell.container)
                : this.getFirstRowCell(cell.y + 1, cell.container);
        }

        if (nextCell == null) {
            // No valid cell above in current container, try to jump containers
            const containersOrder = this._containersRenderOrder;
            const currentContainerIndex = containersOrder.findIndex(x => x == cell!.container);
            const nextContainer = backwards
                ? containersOrder[currentContainerIndex - 1]
                : containersOrder[currentContainerIndex + 1];
            if (nextContainer) {
                const rowIndex = backwards
                    ? this.getLastRowIndex(nextContainer)
                    : 0;
                if (rowIndex != null) {

                    nextCell = backwards
                        ? this.getLastRowCell(rowIndex, nextContainer)
                        : this.getFirstRowCell(rowIndex, nextContainer);
                }
            }
        }

        if (nextCell) {
            this.setFocus(nextCell);
            this.setSingleCellSelection(nextCell);
            if (nextCell.container === GridSelectionContainer.Filter) {
                const element = this._getCellElement(nextCell);

                window.requestAnimationFrame(() => {
                    element?.querySelector('input')?.focus();
                    element?.querySelector('input')?.select();
                });
            }
        }
    }

    private _onSpaceKeyDown(pEvent: KeyboardEvent) {
        if (this.editMode || this._dataGridControl.isTable) { return; } // Temp skip space hotkey for tables
        const cell = this.activeCell;
        switch (cell?.container) {
            case GridSelectionContainer.Main:
            case GridSelectionContainer.NewRecords:
                pEvent.preventDefault();
                const item = this.getItemFromCell(cell);
                if (item) {
                    item.isSelected = !item.isSelected;
                }
                return;
            default:
                return;

        }
    }

    private _handleNavigationKeyDown(pEvent: KeyboardEvent) {
        if (this.editMode || this._shouldSkipEventHandler(this.activeCell)) { return; }
        pEvent.preventDefault();
        switch (pEvent.key) {
            case KeyCode.UP:
                this.navigateUp({
                    appendArea: pEvent.shiftKey,
                    navigateMax: pEvent.ctrlKey
                });
                break;
            case KeyCode.DOWN:
                this.navigateDown({
                    appendArea: pEvent.shiftKey,
                    navigateMax: pEvent.ctrlKey
                });
                break;
            case KeyCode.LEFT:
                this.navigateLeft({
                    appendArea: pEvent.shiftKey,
                    navigateMax: pEvent.ctrlKey
                });
                break;
            case KeyCode.RIGHT:
                this.navigateRight({
                    appendArea: pEvent.shiftKey,
                    navigateMax: pEvent.ctrlKey
                });
                break;
        }
    }

    private _handleDefaultKeyDown(pEvent: KeyboardEvent) {
        const monad = keyboardShortcutMonad(pEvent, this._getKeyboardShortcutUtils())
            .bind({
                keyCombos: [
                    { key: 's', ctrlKey: true },
                    { key: 'S', ctrlKey: true },
                ],
                action: () => {
                    if (this._handleSave(pEvent)) {
                        pEvent.preventDefault();
                        return true;
                    }
                    return false;
                }
            })
            .bind({
                keyCombos: [
                    { key: 'c', ctrlKey: true },
                    { key: 'C', ctrlKey: true },
                ],
                action: () => {
                    if (this._handleCopy(pEvent)) {
                        pEvent.preventDefault();
                        return true;
                    }
                    return false;
                }
            })
            .bind({
                keyCombos: [
                    { key: 'v', ctrlKey: true },
                    { key: 'V', ctrlKey: true },
                ],
                action: () => {
                    if (this._handlePaste(pEvent)) {
                        pEvent.preventDefault();
                        return true;
                    }
                    return false;
                }
            })
            .bind({
                keyCombos: [
                    { key: 'd', ctrlKey: true },
                    { key: 'D', ctrlKey: true },
                ],
                action: () => {
                    if (this._handleCopyFromAbove()) {
                        pEvent.preventDefault();
                        return true;
                    }
                    return false;
                }
            })
            .bind({
                keyCombos: [
                    { key: `'`, ctrlKey: true },
                ],
                action: () => {
                    if (this._handleCopyFromAbove()) {
                        pEvent.preventDefault();
                        this.enterEditMode();
                        return true;
                    }
                    return false;
                }
            })
            .bind({
                keyCombos: [
                    { key: 'n', altKey: true },
                    { key: 'N', altKey: true },
                ],
                action: () => {
                    if (this._focusNewRecord()) {
                        pEvent.preventDefault();
                        return true;
                    }
                    return false;
                }
            });

        if (!monad.handled) {
            Array.from(this._registeredShortcuts.values()).reduce((_, shortcut) => {
                monad.bind(shortcut);
                return monad;
            }, monad);
        }

        if (!monad.handled && !pEvent.ctrlKey && !this.editMode && /^[a-zA-Z0-9]$/.test(pEvent.key)) {
            this._handleAlphaNumericalKey(pEvent);
        }
    }

    private _handleAlphaNumericalKey(pEvent: KeyboardEvent) {
        const cell = this._activeCell;
        if (cell == null || ![GridSelectionContainer.Main, GridSelectionContainer.NewRecords].includes(cell.container) || !this.isEditable(cell)) {
            return false;
        }
        pEvent.preventDefault();
        const item = this.getItemFromCell(cell);
        const column = this._dataGridControl.dataColumns.columns[cell.x];
        const field = column?.field;
        let selectionRange: [number, number] | undefined = undefined;
        try {
            const selection = window.getSelection();
            if (selection?.anchorNode?.nodeType === window.Node.TEXT_NODE) {
                selectionRange = [selection.anchorOffset, selection.anchorOffset];
            }
        } catch (_ex) {
            // skip setting selection range
        }

        if (item && field && column.cellEditor && column.cellEditorSlot == null) {
            // Only update vaules for default editors
            if (selectionRange) {
                this._dataGridControl.selectionControl.gridDataPaste([[pEvent.key]], this._dataGridControl.dataColumns.columns, [item], [column])
            } else {
                (item as any)[field] = pEvent.key; // TODO: Safe parse value
            }
        }
        if (selectionRange) {
            selectionRange[0]++;
            selectionRange[1]++;
        }
        this.enterEditMode({
            selectionRange: selectionRange
        });
        return true;
    }

    private _handleCopyFromAbove() {
        const cell = this._activeCell;
        if (!cell || ![GridSelectionContainer.Main, GridSelectionContainer.NewRecords].includes(cell.container)) {
            return false;
        }
        let cellAbove = this.getNextValidCellAbove(cell);

        if (cellAbove == null && cell.container === GridSelectionContainer.NewRecords) {
            const lastRowIndex = this._dataGridControl.isTable
                ? this.getLastRowIndex(GridSelectionContainer.Main)
                : this._getLastVisibleRowIndex();

            if (lastRowIndex != null) {
                cellAbove = { x: cell.x, y: lastRowIndex, container: GridSelectionContainer.Main };
            }
        }

        if (cellAbove) {
            const itemAbove = this.getItemFromCell(cellAbove);
            const item = this.getItemFromCell(cell);
            const field = this._dataGridControl.dataColumns.columns[cellAbove.x]?.field;
            if (itemAbove == null || item == null || field == null) { return false; }
            (item as any)[field] = (itemAbove as any)[field];
            return true;
        }
        return false;
    }

    private _handleSave(_pEvent: KeyboardEvent) {
        const cell = this._activeCell;
        if (cell == null || ![GridSelectionContainer.Main, GridSelectionContainer.NewRecords].includes(cell.container)) {
            return false;
        }
        const item = this.getItemFromCell(cell);
        if (item == null || !item.hasChanges || item.isSaving) { return false; }
        if (item.save) {
            item.save();
            if (this.editMode) {
                this.exitEditMode();
                const cellElement = this._getCellElement(cell);
                cellElement?.focus();
            }
            return true;
        }
        return false;
    }

    private _handleCopy(_pEvent: KeyboardEvent) {
        const cell = this.activeCell;
        if (cell == null || ![GridSelectionContainer.Main, GridSelectionContainer.NewRecords].includes(cell.container)) {
            return false;
        }
        // if ((pEvent.target as HTMLElement).tagName === 'INPUT') {
        // return false;
        // }
        // const target: HTMLElement = pEvent.target as any;
        const selection = document.getSelection();
        if (selection && selection.toString()) {
            return false;
        }
        // const selection = document.getSelection();
        // if (selection && selection.focusNode && target.contains != null) {
        //     if (target.contains(selection.focusNode) && selection.toString()) {
        //         return false;
        //     }
        // }
        this._dataGridControl.selectionControl?.copySelection(false, this._dataGridControl.dataColumns.columns);
        this.container?.querySelectorAll<HTMLElement>('.o365-cell-range-selected, .o365-cell-range-single-cell').forEach(cell => cell.classList.add('o365-cell-copy-highlight-animation'))
        setTimeout(() => {
            this.container?.querySelectorAll<HTMLElement>('.o365-cell-copy-highlight-animation').forEach(cell => cell.classList.remove('o365-cell-copy-highlight-animation'));
        }, 1001)
        return true;
    }
    private _handlePaste(pEvent: KeyboardEvent) {
        const cell = this.activeCell;
        if (cell == null || ![GridSelectionContainer.Main, GridSelectionContainer.NewRecords].includes(cell.container)) {
            return false;
        }
        if (['INPUT', 'TEXTAREA'].includes((pEvent.target as HTMLElement).tagName) || (pEvent.target as HTMLElement)?.isContentEditable) {
            return false;
        }
        this._dataGridControl.selectionControl?.pasteSelection(pEvent, this._dataGridControl.dataColumns.columns).then((pasted: boolean) => {
            if (pasted) {
                this.container?.querySelectorAll<HTMLElement>('.o365-cell-range-selected, .o365-cell-range-single-cell').forEach(cell => cell.classList.add('o365-cell-paste-highlight-animation'))
                setTimeout(() => {
                    this.container?.querySelectorAll<HTMLElement>('.o365-cell-paste-highlight-animation').forEach(cell => cell.classList.remove('o365-cell-paste-highlight-animation'));
                }, 1001)
            }
        });
        return true;
    }

    private _focusNewRecord(): boolean | void {
        const dataObject = this._dataGridControl.dataObject;
        const isTable = this._dataGridControl.isTable;
        if (dataObject == null) { return false; }
        if (!dataObject.allowInsert || this._dataGridControl.props.hideNewRecords || this._dataGridControl.props.disableBatchRecords) {
            return;
        }

        if (!isTable && !dataObject.batchDataEnabled) {
            this._dataGridControl.enableBatchRecords();
            return true;
        } else {
            const index = this.getLastRowIndex(GridSelectionContainer.NewRecords);
            const cell = this.getFirstRowCell(index || 0, GridSelectionContainer.NewRecords, true);
            if (cell) {
                this.setSingleCellSelection(cell)
                this.setFocus(cell);
                this.enterEditMode();
                return true;
            }
        }
    }


    private _toggleBatchData() {
        const dataObject = this._dataGridControl.dataObject;
        const isTable = this._dataGridControl.isTable;
        if (dataObject == null) { return false; }
        if (!dataObject.allowInsert || this._dataGridControl.props.hideNewRecords || this._dataGridControl.props.disableBatchRecords) {
            return;
        }
        if (isTable) {
            const cell = this.getFirstRowCell(0, GridSelectionContainer.NewRecords, true);
            if (cell) {
                this.setSingleCellSelection(cell)
                this.setFocus(cell);
                this.enterEditMode();
                return true;
            }
            return;
        }
        if (dataObject.batchDataEnabled) {
            if (dataObject.batchData.data.every(x => !x.hasChanges)) {
                this._dataGridControl.closeBatchRecords();
            }
        } else {
            this._dataGridControl.enableBatchRecords();
        }
        return true;
    }

    private _getClosestCellFromEvent(e: MouseEvent) {
        const target = <HTMLElement>e.target;
        const closestEl = <HTMLElement>target.closest('[data-o365-colindex]');
        return closestEl;
    }

    private _isCellEditorRef(pRef: HTMLElement | CellEditorRef): pRef is CellEditorRef {
        return (pRef as CellEditorRef).$ != undefined;
    }

    private _getCellElement(pCell: Cell) {
        const element: HTMLElement | null = this.container?.querySelector(`[data-o365-container="${pCell.container}"] [data-o365-rowindex="${pCell.y}"] [data-o365-colindex="${pCell.x}"]`) ?? null;
        return element;
    }

    private _getLocationFromElement(pElement: HTMLElement): Cell | null {
        const cell = pElement.closest('[data-o365-colindex]') as HTMLElement;
        if (!cell) { return null; }
        const row = pElement.closest('[data-o365-rowindex]') as HTMLElement;
        if (!row) { return null; }
        const container = pElement.closest('[data-o365-container]') as HTMLElement;
        if (!container) { return null; }
        return {
            x: +cell.dataset.o365Colindex!,
            y: +row.dataset.o365Rowindex!,
            container: container.dataset.o365Container! as GridSelectionContainer,
        };
    }

    /** Returns a set of suppressed column orders */
    private _getSelectionSuppressedColumns() {
        const suppressedOrders = new Set<number>();
        this._dataGridControl.dataColumns.columns.forEach(col => {
            if (col.suppressSelection) {
                suppressedOrders.add(col.order);
            }
        });
        return suppressedOrders;
    }

    private _equalLocations(a: Cell, b: Cell) {
        return a.x === b.x && a.y === b.y && a.container === b.container;
    }

    private _locationIsInSelection(pCell: Cell) {
        if (pCell == null || this.activeSelection == null) { return false; }
        const end = this.activeSelection.end;
        const start = this.activeSelection.start;
        if (end == null || start == null) { return; }
        const xPositive = end.x - start.x >= 0;
        const yPositive = end.y - start.y >= 0;

        const xIsBetween = xPositive
            ? start.x <= pCell.x && pCell.x <= end.x
            : end.x <= pCell.x && pCell.x <= start.x;

        const yIsBetween = yPositive
            ? start.y <= pCell.y && pCell.y <= end.y
            : end.y <= pCell.x && pCell.y <= start.y;

        return xIsBetween && yIsBetween;
    }

    private _shouldSkipEventHandler(pCell?: Cell | null) {
        switch (pCell?.container) {
            case GridSelectionContainer.Header:
            case GridSelectionContainer.Filter:
            case GridSelectionContainer.Summary:
                const element = this._getCellElement(pCell);
                return element !== document.activeElement;
            case GridSelectionContainer.NewRecords:
            case GridSelectionContainer.Main:
                return false;
            default:
                return true;
        }
    }

    private _getKeyboardShortcutUtils(): KeyboardShortcutUtils {
        return {
            getCell: () => this._activeCell ?? undefined,
            getItem: () => {
                if (this._activeCell) {
                    return this.getItemFromCell(this._activeCell);
                } else {
                    return undefined;
                }
            }
        }
    }

    private _deleteItem(pCell: Cell, pEvent?: KeyboardEvent) {
        const item = this.getItemFromCell(pCell);
        if (item == null || item.delete == null) { return; }
        if (pEvent) { pEvent.preventDefault(); }
        item.delete();
    }

    /**
     * Get the last visible row index in the main list.
     * Should not be called frequently. WIll query select rendered rows and loop through their transformed position. 
     * 
     * Only available for data grid 
     */
    private _getLastVisibleRowIndex() {
        if (this._dataGridControl.isTable) { return undefined; }
        const containerQuery = `[data-o365-container="${GridSelectionContainer.Main}"]`;
        const viewportQuery = '.o365-body-left-pinned-cols';
        const scrollContainer = this._dataGridControl.container?.querySelector<HTMLElement>(this._dataGridControl._gridQuery('.o365-main-list'));
        if (scrollContainer == null) { return; }
        const rows = this._dataGridControl.container?.querySelectorAll<HTMLElement>(this._dataGridControl._gridQuery(`${containerQuery} ${viewportQuery} .o365-body-row`));
        const visibleBoundry = scrollContainer.clientHeight + scrollContainer.scrollTop;
        if (rows) {
            let lastIndex = undefined;
            let maxPos = -1;
            rows.forEach(row => {
                const index = row.dataset.o365Rowindex ? +row.dataset.o365Rowindex : undefined;
                if (index == null) { return; }
                const str = row.style.transform as string;
                if (str == null) { return; }
                const pos = parseInt((str.match(/\d+/) || [''])[0]) + (row.clientHeight / 2);

                if (pos > maxPos && pos <= visibleBoundry) {
                    lastIndex = index;
                    maxPos = pos;
                }
            });

            return lastIndex;
        }

        return undefined;
    }

    private _getRowHeight(pIndex?: number) {
        return typeof this._dataGridControl.props.rowHeight == 'number' || typeof this._dataGridControl.props.rowHeight == 'string'
            ? +this._dataGridControl.props.rowHeight
            : 34;
    }
}

export enum GridSelectionContainer {
    Header = 'H',
    NewRecords = 'N',
    Filter = 'F',
    Main = 'G',
    Summary = 'S'
};

export enum KeyCode {
    TAB = 'Tab',
    LEFT = 'ArrowLeft',
    UP = 'ArrowUp',
    RIGHT = 'ArrowRight',
    DOWN = 'ArrowDown',
    F2 = 'F2',
    ESC = 'Escape',
    ENTER = 'Enter',
    DEL = 'Delete',
    BACKSPACE = 'Backspace',
    SPACE = ' ',
};

export type Cell = {
    container: GridSelectionContainer;
    x: number;
    y: number;
}

export type AreaSelection = {
    start: Cell;
    end: Cell;
}

export type NavigationModifiers = {
    appendArea?: boolean;
    /** Navigate to the last valid cell in the navigation direction */
    navigateMax?: boolean;
};

export type SelectionClassMap = Record<string, Record<number, Record<number, string[]>>>;

// type Ref<T> = { value: T };

export type CellEditorRef = {
    $?: {
        subTree?: {
            component?: {
                ctx?: CellEditorRef
            }
        },
    },
    $el?: HTMLElement,
    $refs?: {
        popupRef?: CellEditorRef,
        editorRef?: CellEditorRef
    },
    editorRef?: CellEditorRef,
    activateEditor?: (pOptions?: {
        selectionRange?: [number, number]
    }) => void,

    nextElementSibling?: HTMLElement,
    parentElement?: HTMLElement

}

/** Compatibility layer for previous navigation implementation */
export class NavigationControl_Compatibility {
    private _navigation: DataGridNavigation;

    get renderSelection() {
        return this._navigation.renderSelection;
    }

    get focusFirstEditableCell() {
        return this._navigation.focusFirstEditableCell;
    }

    constructor(pNavigation: DataGridNavigation) {
        this._navigation = pNavigation;
    }
};

/** Compatibility layer for previous selection implementation */
export class GridSelectionInterface_Compatibility {
    private _navigation: DataGridNavigation;

    get selectionClassMap() {
        return this._navigation.selectionClassMap;
    }

    get selection() {
        return this._navigation.activeSelection;
    }

    get areaStart() {
        return this._navigation.activeSelection?.start;
    }

    get areaEnd() {
        return this._navigation.activeSelection?.end;
    }

    get isSingleSelection() {
        return this._navigation.isSingleSelection;
    }

    get clearSelection() {
        return this._navigation.clearSelection.bind(this._navigation);;
    }

    get setSingleSelection() {
        return this._navigation.setSingleCellSelection.bind(this._navigation);
    }

    constructor(pNavigation: DataGridNavigation) {
        this._navigation = pNavigation;
    }

}

/** Compatibility layer for previous focus implementation */
export class GridFocusControl_Compatibility {
    private _navigation: DataGridNavigation;

    get isDragging() {
        return this._navigation.isDragging;
    }

    get activeCell() {
        return this._navigation.activeCellString;
    }

    get activeCellLocation() {
        return this._navigation.activeCell;
    }

    get editMode() {
        return this._navigation.editMode;
    }

    get focusedThroughClick() {
        return this._navigation.focusedThroughClick;
    }
    set focusedThroughClick(pValue) {
        this._navigation.focusedThroughClick = pValue;
    }

    get clearFocus() {
        return this._navigation.clearFocus.bind(this._navigation);;
    }

    get setFocusToCell() {
        return this._navigation.setFocus.bind(this._navigation);;
    }

    get exitEditMode() {
        return this._navigation.exitEditMode.bind(this._navigation);
    }

    constructor(pNavigation: DataGridNavigation) {
        this._navigation = pNavigation;
    }
}

function keyboardShortcutMonad(pEvent: KeyboardEvent, pUtils: KeyboardShortcutUtils) {
    const monad = {
        handled: false,
        bind: (pShortcut: KeyboardShortcut<[KeyboardShortcutUtils]>) => {
            if (!monad.handled) {
                const matches = pShortcut.keyCombos.some(combo => {
                    if (combo.key === pEvent.key) {
                        let modifiersMatch = true;
                        if (pShortcut.validator) {
                            modifiersMatch = pShortcut.validator(pEvent);
                        } else {
                            if (combo.ctrlKey && !pEvent.ctrlKey) {
                                modifiersMatch = false;
                            } else if (combo.altKey && !pEvent.altKey) {
                                modifiersMatch = false;
                            } else if (combo.shiftKey && !pEvent.shiftKey) {
                                modifiersMatch = false;
                            }
                        }
                        return modifiersMatch;
                    } else {
                        return false;
                    }
                });
                if (matches) {
                    monad.handled = pShortcut.action(pEvent, pUtils) ?? false;
                }
            }
            return monad;
        },
    }
    return monad;
}


function keyComboToString(pCombo: KeyCombo) {
    return `k:${pCombo.key};ctrl:${pCombo.ctrlKey ? 1 : 0};alt:${pCombo.altKey ? 1 : 0};shift:${pCombo.shiftKey ? 1 : 0};`;
}

export type KeyboardShortcutUtils = {
    getCell: () => Cell | undefined;
    getItem: () => DataItemModel | undefined,
};

export type KeyboardShortcut<T extends any[]> = {
    keyCombos: KeyCombo[];
    action: (pEvent: KeyboardEvent, ...args: T) => boolean;
    /** Optional validator. When provided will be used isntead of default modifiers check */
    validator?: (pEvent: KeyboardEvent) => boolean;
}

export type KeyCombo = {
    key: string,
    ctrlKey?: boolean,
    altKey?: boolean,
    shiftKey?: boolean,
};